.vendorWrapper {
    margin: 0 auto;
    padding: 2%;
    background: linear-gradient(89.92deg, #120b17 0%, #4b0950 70%, #4b0950 100%);
    height: 100%;
    min-height: 100vh

}
.vendorCard {
    width: 50%;
    margin: 0 auto;
}
.vendorBox {
    border: 1px solid #f1f1f1;
    padding: 20px;
    background: #f7f7f7;
    box-shadow: inset 0 0 10px -6px #848484;
    border-radius: 6px;
}
.vendorLogoWrapper img {
    width: 30%;
}
.vendorInput{
    margin-bottom: 10px;
    position: relative;
}
.vendorInput input, .vendorInput textarea, .vendorForm select{
    font-size: 13px;
    padding-right: 35px;
}
.vendorLogoWrapper{
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 20px;
    margin-bottom: 20px;
    text-align: center;
}
.vendorForm .lableText{
    font-size: 13px;
    display: flex;
    margin-bottom: 7px;
    align-items: flex-start;

}
.eyeset {
    position: absolute;
    bottom: 6px;
    right: 8px;
    z-index: 9;
}
.vendorForm .lableText input{
    margin-right: 10px;
}
.vendorForm .lableText span {
    color: #f21d1d;
    font-weight: 600;
}
.flexCaptcha>div{
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    align-items: center;
}
.submitFormBtn{
    background: linear-gradient(89.92deg, #120b17 0%, #4b0950 70%, #4b0950 100%);
    color: #fff;
    border: none;
    padding: 10px 18px;
    border-radius: 6px;
    font-size: 14px;
    margin-top: 20px;
    width: 50%;
    font-weight: 600;
}
.plusBtn{
    background: linear-gradient(89.92deg, #120b17 0%, #4b0950 70%, #4b0950 100%);
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    width: 30px;
    font-weight: 600;
    height: 30px;
}
.form-control:focus {
    border-color:#dee2e6;
    outline: 0;
    box-shadow: unset;
}
input[type='radio'], input[type='checkbox']{
    accent-color: #de5073;
}
label.sc-aXZVg.guZdik {
    position: relative;
    display: block;
    text-align: center;
    min-width: unset;
    max-width: unset;
    height: 100px;
    border: 2px dashed #bcbcbc;
    padding: 8px 16px 8px 8px;
    border-radius: 5px;
    cursor: pointer;
    -webkit-box-flex: 0;
    flex-grow: 0;
}
label.sc-aXZVg .jWkLDY .file-types{
    display: contents;
}
label.sc-aXZVg .jWkLDY > span{
    display: block;
}
label.sc-aXZVg .jWkLDY{
    display: block;
}
.ReactModal__Overlay{
    background-color: #000000cc !important;
}
button.btnBordered {
    background: linear-gradient(89.92deg, #120b17 0%, #4b0950 70%, #4b0950 100%);
    color: #fff;
    border: none;
    padding: 8px 10px;
    border-radius: 6px;
    font-size: 12px;
    width: auto;
    font-weight: 600;
    margin-right: 10px;
}
.captureImage{
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin-bottom: 10px;
}