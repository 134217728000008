
.category-product-page {
  padding: 20px;
}

.category-product-page .category-title {
  font-size: 28px;
  margin-bottom: 20px;
}

.category-product-page .filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 40px 10px #5936A514;
  padding: 1rem;
  border-radius: 20px;
  padding-left: 2rem;
}

.category-product-page .filter-dropdown {
  position: relative;
}

.category-product-page .filter-button,
.category-product-page .reset-button {
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
  color: #7D8398;
}

.category-product-page .filter-button span {
  color: #E491A8;
}

.category-product-page .reset-button {
  background: linear-gradient(89.92deg, #120b17 0%, #4b0950 70%, #4b0950 100%);;
  border-radius: 50px;
  color: #fff;
}

.category-product-page .dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: max-content;
  min-width: 150px;
  z-index: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

.category-product-page .dropdown-option {
  padding: 10px 20px;
  cursor: pointer;
  color: #7D8398;
}

.category-product-page .dropdown-option:hover {
  background-color: #f0f0f0;
}

.category-product-page .products-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 21px;
  justify-content: start;
  align-items: start;
}

.category-product-page .pro-card {
  border: 1px solid #eee;
  border-radius: 15px;
  text-align: center;
  background: #F8F8F8;
  margin: 10px;
  width: 18rem;
  padding: 0;
  /* height: auto; */
}
.category-product-page .pro-card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
  cursor: pointer;
}

.category-product-page .card-link{
  height: 100%;
  display: flex;
  flex-direction: column;
}
.category-product-page .card-imagee {
  /* width: 60%; */
  height: 15rem !important;
  margin: 20px;
  margin-bottom: 0;
}
.category-product-page .card-imagee img {
  height: 100%;
}

.category-product-page .card-title {
  font-size: 18px;
  margin-top: 10px;
  color: black;
}

.category-product-page .card-rating {
  color: #4b0950;
}

.category-product-page .card-prices {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.category-product-page .card-offer-price {
  font-size: 14px;
  color: #808080;
  margin-top: 10px; 
  text-decoration: line-through;
}
.category-product-page .card-price {
  font-size: 16px;
  color: #4b0950;
  margin-top: 10px;
  font-weight: 600;
}

.category-product-page .card-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #fff;
  border-radius: 15px;
  width: 100%;
  padding: 10px;
}

.category-product-page .card-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 18px;
  padding: 10px;
  border-radius: 5px;
}

.category-product-page .card-button.heart {
  color: #120b17;
}

.category-product-page .card-button.compare {
  color: #120b17;
}

.category-product-page .card-button.add-to-cart {
  background: linear-gradient(89.92deg, #120b17 0%, #4b0950 70%, #4b0950 100%);
  color: #fff;
  padding: 10px 15px;
  border-radius: 50px;
  font-size: 14px;
}
