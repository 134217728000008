/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*{
  font-family: "Montserrat", sans-serif !important;
} */

.faq-page {
  display: flex;
}

.faq-sidebar {
  width: 250px;
  padding: 20px;
  background-color: #f8f8f8;
  border-right: 1px solid #ddd; 
  flex-shrink: 0; 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.faq-sidebar h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.faq-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.faq-sidebar ul li {
  margin: 10px 0;
  padding: 10px 0; 
  cursor: pointer;
}

.faq-sidebar ul li:hover {
  text-decoration: none;
}

.faq-sidebar ul li.active {
  border-right: 5px solid red; 
}

.faq-content {
  flex-grow: 1; 
  padding: 20px;
  background-color: #fff; 
  margin-left: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.faq-content h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.faq-content p {
  font-size: 16px;
  margin-bottom: 20px;
}

.feedback {
  display: flex;
  align-items: center;
}

.feedback p {
  margin-right: 10px;
}

.feedback button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #f8f8f8; 
  border: 1px solid #ddd; 
  border-radius: 4px; 
}
