.card-header-text {
  background-image:linear-gradient(89.92deg, #120b17 0%, #4b0950 70%, #4b0950 100%);
  text-align: center;
}
.mesh-bg {
    height : 100dvh;
    background:linear-gradient(89.92deg, #120b17 0%, #4b0950 70%, #4b0950 100%);
    background-size: cover;
    background-position: center;
  }
  .home-card {
    cursor:pointer;
    transition: all .1s linear;
  }

  .home-card:hover {
       scale : 1.05
  }
  .drop-container {
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    padding: 20px;
    border-radius: 10px;
    border: 2px dashed #555;
    color: #444;
    cursor: pointer;
    transition: background .2s ease-in-out, border .2s ease-in-out;
  }
  
  .drop-container:hover {
    background: #eee;
    border-color: #111;
  }
  
  .drop-container:hover .drop-title {
    color: #222;
  }
  
  .drop-title {
    color: #444;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
  }

  .activeClass {
     background: #ffffff19;
     width: 100%;
  }

  .earning-card {
    height: 100px;
    border-radius: 1.5rem;
    margin-inline: .5rem;
    box-shadow: 0px 0px 4px 2px rgba(154, 154, 154, 0.151);
    display : flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
  
  .earning-card p {
     color : gray;
     font-size : 1.3rem
  }

  .project-card:hover {
     transition: all .2s ease-in;
     scale : 1.05;
     background: lightgray;
  }

  .products {
     display: grid;
     grid-template-columns: repeat(4, 1fr);
     grid-template-rows: repeat(2, 1fr);
     gap: 1rem;
     justify-content: center;
    }
    
    .product-card {
      width : auto;
      min-height : 35rem;
      background: #ffffff !important;
      position: relative;
    }

    .proudct-image-container {
       height: 200px;
       background: #f1f1f1;
    }

  .proudct-image  {
     width: 10rem;
     height:10rem;
     object-fit: contain;
  }

  .products .card-footer {
     position:absolute;
     bottom : 0;
     width: 100%;
  }

  @media screen and (max-width: 1024px) {
    .products {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media screen and (max-width: 600px) {
     .products {
       grid-template-columns: repeat(2, 1fr);
     }
  }

  @media screen and (max-width: 400px) {
    .products {
     grid-template-columns: repeat(1, 1fr);
    }
 }
    .activeClass {
       background: #ffffff19;
       width: 100%;
    }

    .earning-card {
      height: 100px;
      border-radius: 1.5rem;
      margin-inline: .5rem;
      box-shadow: 0px 0px 4px 2px rgba(154, 154, 154, 0.151);
      display : flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

    }
    
    .earning-card p {
       color : gray;
       font-size : 1.3rem
    }

    .project-card:hover {
       transition: all .2s ease-in;
       scale : 1.05;
       background: lightgray;
    }
   
    Main container styles
.w-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin-top: 1rem;
  }
  
  .w-form-wrapper {
    width: 100%;
    min-width: 50%;
  }
  
  .w-form-card {
    background-color: white;
    border-radius: 1rem;
    outline: 5px solid rgb(241, 241, 241);
    padding: 2rem;
  }
  
  .w-form-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #1f2937;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .w-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .w-form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .w-form-label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #374151;
  }
  
  .w-input-wrapper {
    position: relative;
  }
  
  .w-input-icon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: #9ca3af;
    width: 1.25rem;
    height: 1.25rem;
    pointer-events: none;
  }
  
  .w-form-input {
    display: block;
    width: 100%;
    padding: 0.625rem 0.75rem;
    padding-left: 2.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.5rem;
    transition: all 0.2s;
  }
  
  .w-form-input:focus {
    outline: none;
    border-color: #822457 ;
    box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.2);
  }
  
  .w-submit-button {
    width: 100%;
    background-color: #822457 ;
    color: white;
    padding: 0.625rem 1rem;
    border-radius: 0.5rem;
    font-weight: 500;
    transition: background-color 0.2s;
    border: none;
    cursor: pointer;
  }
  
  .w-submit-button:hover {
    background-color: #822457;
  }
  
  .w-submit-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #822457 ;
  }


  
  #payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }
  
  #payment-element {
    margin-bottom: 24px;
  }
  
  /* Buttons and links */
  button.pay-button {
    background: #822457 ;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  
  button.pay-button:hover {
    filter: contrast(115%);
  }
  
  button.pay-button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: '';
  }
  
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #822457 ;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #822457 ;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  
  /* Payment status page */
  #payment-status {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 30px;
    width: 30vw;
    min-width: 500px;
    min-height: 380px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
    opacity: 0;
    animation: fadeInAnimation 1s ease forwards;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  #status-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  
  h2 {
    margin: 0;
    color: #30313D;
    text-align: center;
  }
  
  a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    font-family: Arial, sans-serif;
    display: block;
  }
  a:hover {
    filter: contrast(120%);
  }
  
  #details-table {
    overflow-x: auto;
    width: 100%;
  }
  
  table {
    width: 100%;
    font-size: 14px;
    border-collapse: collapse;
  }
  table tbody tr:first-child td {
    border-top: 1px solid #E6E6E6; /* Top border */
    padding-top: 10px;
  }
  table tbody tr:last-child td {
    border-bottom: 1px solid #E6E6E6; /* Bottom border */
  }
  td {
    padding-bottom: 10px;
  }
  
  .TableContent {
    text-align: right;
    color: #6D6E78;
  }
  
  .TableLabel {
    font-weight: 600;
    color: #30313D;
  }
  
  #view-details {
    color: #822457 ;
  }
  
  #retry-button {
    text-align: center;
    background: #822457 ;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes fadeInAnimation {
    to {
        opacity: 1;
    }
  }
  
  @media only screen and (max-width: 600px) {
    form, #payment-status {
      width: 80vw;
      min-width: initial;
    }
  }

  .table-row  {
    text-align: center !important;
 }