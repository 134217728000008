@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.krizaar-pay{
  color: white;
  background: black;
}


.krizaar-pay .custom-navbar {
  /* background: rgba(44, 22, 86, 1); */
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.5) !important;
  padding: 10px 100px !important;
}

.krizaar-pay .nav-item {
  text-align: center;
}


.krizaar-pay .nav-link {
  /* color: white !important; */
  padding-top: 10px;
  padding-bottom: 10px;
}

.krizaar-pay .navbar-toggler {
  color: white !important;
  background-color: white !important;
}

.krizaar-pay .hero-section-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.krizaar-pay .hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 2rem 4rem 2rem;
  color: white;
  margin-top: 4rem;
  /* background: linear-gradient(180deg, rgba(44, 22, 86, 1) 0%, #7D3560 100%); */
}

.krizaar-pay .hero-text {
  max-width: 50%;
}

.krizaar-pay .hero-text h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.krizaar-pay .hero-text p {
  font-size: 1.25rem;
  /* margin-bottom: 2rem; */
}

.krizaar-pay .hero-text a {
  font-weight: 600;
  text-decoration: none;
  border-bottom: 1px solid #000;
  color: #000;
}

.krizaar-pay .hero-text .btn {
  font-size: 1rem !important;
  padding: 0.75rem 1.5rem !important;
  margin-bottom: 1rem;
}

.krizaar-pay .hero-image {
  max-width: 45%;
  margin-bottom: 2rem;
}

.krizaar-pay .hero-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}




.krizaar-pay .why-choose-us {
  /* margin-bottom: 5rem; */
}

.krizaar-pay .alignment-one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 2rem;
  /* background-color: #fff; */
}


.krizaar-pay .alignment-one h2, .alignment-two h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: white;
}

.krizaar-pay .alignment-one-text {
  max-width: 50%;
  padding-left: 3rem;
}

.krizaar-pay .alignment-two {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  /* background-color: #fff; */
}

.krizaar-pay .alignment-two .alignment-one-text {
  max-width: 50%;
  padding-left: 0;
}

.krizaar-pay .why-choose-us-heading {
  padding-top: 5rem;
  text-align: center;
  font-size: 2.8rem;
  margin-bottom: 1rem;
  color: white;
}

.krizaar-pay .alignment-one-text p {
  font-size: 1.1rem;
}

.krizaar-pay .alignment-one-text a {
  font-weight: 600;
  text-decoration: none;
  border-bottom: 1px solid #000;
  color: #000;
}

.krizaar-pay .alignment-one-text .btn {
  font-size: 1rem !important;
  padding: 0.75rem 1.5rem !important;
  margin-bottom: 1rem;
}

.krizaar-pay .alignment-one-image {
  max-width: 45%;
}

.krizaar-pay .alignment-one-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}




.krizaar-pay .security-section {
  /* background: url('./SecuritySectionBG.png') no-repeat center center; */
  background-size: cover;
  color: #fff;
  text-align: center;
  padding: 2rem 1rem !important;
}

.krizaar-pay .security-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.krizaar-pay .security-section p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.krizaar-pay .features {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.krizaar-pay .feature-card {
  background-color: #fff;
  color: #000;
  border-radius: 8px;
  padding: 1.5rem;
  max-width: 600px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.krizaar-pay .feature-card img {
  max-width: 50px;
  margin-bottom: 1rem;
}

.krizaar-pay .feature-card h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.krizaar-pay .pricing-plans {
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.krizaar-pay .toggle-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.krizaar-pay .toggle-container-border {
  margin: 20px 0;
  padding: 5px 7px;
  border-radius: 40px;
  border: 2px solid #7D3560;
  width: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.krizaar-pay .toggle-container button {
  background-color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 50%;
  border-radius: 20px;
  /* max-width: fit-content; */
}

.krizaar-pay .toggle-container button:hover {
  background-color: #f3edf1;
  /* color: white; */
}

.krizaar-pay .toggle-container button.active {
  background-color: #7D3560;
  color: white;
}

.krizaar-pay .header-offer {
  background: linear-gradient(89.92deg, #120b17 0%, #4b0950 70%, #4b0950 100%);
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
}

.krizaar-pay .header-offer p {
  font-weight: 800;
}

.krizaar-pay .plans-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.krizaar-pay .plan {
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 23rem;
  text-align: left;
}

.krizaar-pay .plan-header {
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.krizaar-pay .plan-header h2 {
  margin: 0;
}

.krizaar-pay .most-popular {
  background: #7D3560;
  border-radius: 20px;
  padding: 2px 12px;
  font-weight: 700;
  height: fit-content;
  display: flex;
  align-items: center;
  color: #fff;
}

.krizaar-pay .plan-price {
  border-bottom: 1px solid #ddd;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.krizaar-pay .plan-price h3 {
  margin: 0;
  font-size: 3rem;
}

.krizaar-pay .plan-price h3 span {
  font-size: 1rem;
  font-weight: normal;
}

.krizaar-pay .plan-features {
  padding-left: 20px;
  padding-right: 20px;
}

.krizaar-pay .plan-features p {
  font-size: 0.9rem;
  font-weight: 700;
  margin-bottom: 0;
}

.krizaar-pay .plan-features ul {
  list-style-type: none;
  padding: 0;
}

.krizaar-pay .plan-features ul li {
  padding: 5px 0;
  font-size: 0.9rem;
}

.krizaar-pay .try-free-btn {
  background-color: #7D3560;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  width: 90%;
  margin-top: 10px;
  border-radius: 30px;
  font-weight: 700;
  margin-left: 20px;
  margin-right: 20px !important;
  margin-bottom: 20px;
}

.krizaar-pay .try-free-btn:hover {
  background-color: #841b5a;
}

.krizaar-pay .free-trial {
  background-color: rgb(238 250 179);
  text-align: center;
  padding: 6rem 20px;
  font-family: Arial, sans-serif;
}

.krizaar-pay .free-trial h1 {
  font-size: 36px;
  margin-bottom: 1rem;
}

.krizaar-pay .free-trial p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.krizaar-pay .email-signup {
  display: flex;
  justify-content: center;
  margin: 2rem 0rem;
}

.krizaar-pay .email-signup-border {
  border-radius: 30px;
  padding: 5px;
  background: white;
  border: 1px solid #ccc;
}

.krizaar-pay .email-signup input {
  padding: 10px 20px;
  font-size: 0.9rem;
  border-radius: 30px 0px 0px 30px;
  background: transparent;
  width: 300px;
  outline: none;
  border: none;
}

.krizaar-pay .email-signup input::placeholder {
  color: black;
  font-size: 0.9rem;
}

.krizaar-pay .email-signup button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 0 5px 5px 0;
  background-color: black;
  color: white;
  cursor: pointer;
  border-radius: 30px;
}

.krizaar-pay .email-signup button:hover {
  background-color: #333;
}

.krizaar-pay .disclaimer {
  font-size: 0.9rem !important;
}

@media only screen and (min-width: 992px) {
  .krizaar-pay .custom-navbar {
    width: 100%;
    position: fixed !important;
    top: 0 !important;
  }
}

@media only screen and (max-width: 992px) {
  .krizaar-pay .hero-section {
    margin-top: 0;
  }

  .krizaar-pay .hero-text h1 {
    font-size: 2.2rem;
    margin-bottom: 1rem;
  }

  .krizaar-pay .hero-text h5 {
    font-size: 1.4rem;
  }

  .krizaar-pay .hero-text p {
    font-size: 1rem;
  }

  .krizaar-pay .hero-image {
    max-width: 50%;
    margin-left: 2rem;
    margin-bottom: 2rem;
  }

  .krizaar-pay .alignment-one h2, .alignment-two h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: white;
  }

  .krizaar-pay .alignment-one-text p {
    font-size: 1rem;
  }

  .krizaar-pay .alignment-one-image {
    max-width: 50%;
  }

  .krizaar-pay .contact-us-head {
    margin: 1rem 0rem;
  }

  .krizaar-pay .contact-us-head h1 {
    font-size: 3rem;
    font-family: "Montserrat", sans-serif;
  }

}

@media only screen and (max-width: 768px) {
  .krizaar-pay .hero-text h1 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  .krizaar-pay .hero-text h5 {
    font-size: 1.2rem;
  }

  .krizaar-pay .hero-text p, .hero-text span {
    font-size: 0.9rem;
  }

  .krizaar-pay .hero-image {
    max-width: 100%;
    margin-left: 2rem;
    margin-bottom: 2rem;
  }

  .krizaar-pay .alignment-one h2, .alignment-two h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .krizaar-pay .alignment-one-text p {
    font-size: 0.9rem;
  }

  .krizaar-pay .alignment-one-text h5 {
    font-size: 1.2rem;
  }

  .krizaar-pay .alignment-one-text,
  .krizaar-pay .alignment-two .alignment-one-text{
    max-width: 100%;
    padding-left: 0;
    margin-top: 1rem;
  }

  .krizaar-pay .alignment-one-image {
    max-width: 100%;
  }

  .krizaar-pay .contact-us-section {
    padding: 50px 0rem;
    display: flex;
    justify-content: center;
  }

  .krizaar-pay .contact-us-head {
    margin: 1rem 0rem;
  }

  .krizaar-pay .footer-bottom-links{
    display: flex;
    justify-content: center;
    gap: 20px;
  }
}

@media only screen and (max-width: 576px) {

  .krizaar-pay .hero-text {
    max-width: 100%;
  }

  .krizaar-pay .hero-section-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .krizaar-pay .hero-text h1 {
    font-size: 2rem !important;
    margin-bottom: 1rem;
  }

  .krizaar-pay .hero-text h5 {
    font-size: 1.5rem;
  }

  .krizaar-pay .hero-text p, .hero-text span {
    font-size: 1rem;
  }

  .krizaar-pay .hero-image {
    max-width: 100%;
    margin-left: 0rem;
    margin-bottom: 2rem;
  }

  .krizaar-pay .alignment-one, .alignment-two {
    flex-direction: column-reverse;
  }

  .krizaar-pay .alignment-one-text {
    max-width: 100%;
  }

  .krizaar-pay .alignment-two .alignment-one-text {
    max-width: 100%;
  }

  .krizaar-pay .security-section h2{
    font-size: 1.8rem;
  }
  
  .krizaar-pay .security-section h3{
    font-size: 1.2rem;
  }

  .krizaar-pay .security-section p{
    font-size: 1rem;
  }

  .krizaar-pay .alignment-one-image {
    max-width: 100%;
  }

  .krizaar-pay .alignment-one-image img {
    width: 100%;
  }

  .krizaar-pay .contact-us-section {
    padding: 50px 3rem;
    display: flex;
    justify-content: center;
  }

  .krizaar-pay .contact-us-head {
    margin: 1rem 0rem;
  }

  .krizaar-pay .contact-us-head h1 {
    font-size: 2.5rem;
  }

  .krizaar-pay .contact-us-heading {
    font-size: 4rem;
  }

  .krizaar-pay .contact-us-subtitle {
    font-size: 1rem;
  }

  .krizaar-pay .contact-us-form .form-row {
    flex-direction: column;
  }

  .contact-us-form input,
  .krizaar-pay .contact-us-form textarea {
    width: 100%;
    font-size: 20px;
  }

  .contact-us-form input::placeholder,
  .krizaar-pay .contact-us-form textarea::placeholder {
    font-size: 20px;
  }

  .krizaar-pay .custom-navbar {
    padding: 10px 70px !important;
  }

}
@media only screen and (max-width: 500px) {
  .krizaar-pay .contact-us-section {
    padding: 50px 1rem;
    display: flex;
    justify-content: center;
  }

  .krizaar-pay .contact-us-head h1 {
    font-size: 2rem;
    font-family: "Montserrat", sans-serif;
  }
  .krizaar-pay .contact-us-head h3 {
    font-size: 1.3rem;
  }
  .krizaar-pay .contact-us-heading {
    font-size: 3rem;
  }

  .krizaar-pay .contact-us-subtitle {
    font-size: 1rem;
  }

  .krizaar-pay .contact-us-form .form-row {
    display: flex;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;
    flex-direction: column;
  }

  .contact-us-form input,
  .krizaar-pay .contact-us-form textarea {
    width: 100%;
    font-size: 20px;
  }

  .contact-us-form input::placeholder,
  .krizaar-pay .contact-us-form textarea::placeholder {
    font-size: 20px;
  }

  .krizaar-pay .submit-button {
    text-align: left;
    width: 100%
  }

  .krizaar-pay .submit-button button {
    padding: 15px 25px 15px 25px;
  }

}

@media only screen and (max-width: 400px){
  .krizaar-pay .footer-bottom-links .footer-bottom-left{
    display: none !important;
  }
  .krizaar-pay .custom-navbar {
    padding: 10px 30px !important;
  }

  .krizaar-pay .hero-text h1 {
    font-size: 1.8rem !important;
  }
  .krizaar-pay .hero-text h5 {
    font-size: 1.25rem !important;
  }
}
@media only screen and (max-width: 360px){
  .krizaar-pay .contact-us-heading {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}
